import React from "react";
import Seo from "../components/Seo/Seo";
import PageWrapper from "../components/PageWrapper";
import styled from "styled-components";
import { Box, Button } from "../components/Core";
import { device } from "../utils";
import { Container, Image, Row, Col } from "react-bootstrap";
import yflogo from "../assets/image/png/youfibre-logo-new-dark.png";

const BoxStyled = styled(Box)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const BoxInner = styled(Box)`
  min-height: 100vh;
`;

const FormStyled = styled.div`
  padding: 40px 30px;
  @media ${device.sm} {
    padding: 53px 58px 50px;
  }
  box-shadow: ${({theme}) => `0 20px 61px ${theme.colors.shadow}`};
  border-radius: 10px;
  background-color: ${({theme}) => theme.colors.light};
  width: 100%;
  display: block;
  text-align: center;
`;

const SelectProviderPage = (props) => {

    return (
        <div>
            <Seo title='Netomnia - Select Provider' description='Netomnia - Select Provider'/>
            <PageWrapper footerDark>
                <BoxStyled bg="#f7f8fa">
                    <BoxInner className="d-flex align-items-center">
                        <Container>
                        <Box>
                            <FormStyled>
                                <div>
                                    <h4 className="mt-0" style={{ color: '#000' }}>
                                        Choose the available provider
                                    </h4>
                                </div>
                                <br></br>
                                <div>
                                    <a href={process.env.GATSBY_YOUFIBRE_URL}>
                                        <Image alt="" src={yflogo} width={160} style={{ display: 'block', margin: 'auto' }}/>
                                        <Button className="mt-3">
                                            YouFibre Website
                                        </Button>
                                    </a>
                                </div>
                            </FormStyled>
                        </Box>
                        </Container>
                    </BoxInner>
                </BoxStyled>
            </PageWrapper>
        </div>
    )
}

export default SelectProviderPage
